import { motion, AnimatePresence } from 'framer-motion';
import { useParams, Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import QuoteModal from './QuoteModal';
import cctvInstallation from '../assets/cctvInstallation.jpg';
import cybersecurityBg from '../assets/cybersecurityBg.jpg';
import softwareDevBg from '../assets/softwareDevBg.jpg';
import domeCamera from '../assets/domeCamera.jpg';
import bulletCamera from '../assets/bulletCamera.jpg';
import ptzCamera from '../assets/ptzCamera.jpg';
import accessBg from '../assets/accessBg.jpg';
import officeBg from '../assets/officeBg.jpg';
import structureBg from '../assets/structureBg.jpg';
import supportBg from '../assets/supportBg.jpg';
import firewall from '../assets/firewall.jpg';
import networkSecurity from '../assets/networkSecurity.jpg';
import webDev from '../assets/webDev.jpg';
import './ServiceDetail.css';

const servicesData = {
  'home-security': {
    title: 'Home Security & CCTV Installations',
    bgImage: cctvInstallation,
    description: 'Advanced security & intelligence systems',
    sections: {
      overview: {
        title: 'Comprehensive Security Solutions',
        content: `We provide end-to-end security solutions combining cutting-edge hardware with intelligent monitoring systems in Nigeria. 
        Our offerings include professional installation, 24/7 monitoring, and smart home integration.
  `
      },
      cameraTypes: [
        {
          title: 'Dome Cameras',
          image: domeCamera,
          description: 'Discreet 360° coverage with vandal-resistant design',
          specs: ['4K Resolution', 'Night Vision', 'Weatherproof', 'Wide Dynamic Range']
        },
        {
          title: 'Bullet Cameras',
          image: bulletCamera,
          description: 'Long-range surveillance with powerful zoom capabilities',
          specs: ['30x Optical Zoom', '100m IR Night Vision', 'AI Motion Detection', 'Corrosion-Resistant']
        },
        {
          title: 'PTZ Cameras',
          image: ptzCamera,
          description: 'Pan-Tilt-Zoom functionality for active monitoring',
          specs: ['360° Coverage', 'Auto Tracking', 'Preset Positions', '4K Ultra HD']
        }
      ],
      features: [
        'Smartphone Integration',
        'Cloud Storage Options',
        'Facial Recognition',
        'Perimeter Protection'
      ]
    }
  },
  'cybersecurity': {
    title: 'Cybersecurity & IT Distribution',
    bgImage: cybersecurityBg,
    description: 'Next-generation IT infrastructure solutions',
    sections: {
      overview: {
        title: 'Enterprise-Grade Protection',
        content: `Our cybersecurity solutions protect businesses from evolving digital threats while our IT distribution network 
        provides access to cutting-edge hardware and enterprise solutions in Nigeria.
  `
      },
      services: [
        {
          title: 'Network Security',
          image: firewall,
          description: 'Advanced firewall and intrusion prevention systems',
          features: ['Next-gen Firewalls', 'VPN Solutions', 'DDoS Protection', 'SSL Inspection']
        },
        {
          title: 'Endpoint Protection',
          image: networkSecurity,
          description: 'Complete device security across your organization',
          features: ['Antivirus', 'Device Encryption', 'Application Control', 'Threat Intelligence']
        }
      ],
      partners: ['Cisco', 'Fortinet', 'Palo Alto', 'Check Point', 'Juniper','HP','Dell']
    }
  },
  'software-development': {
    title: 'Software & Website Development',
    bgImage: softwareDevBg,
    description: 'Custom digital solutions for modern businesses',
    sections: {
      overview: {
        title: 'Full-Cycle Development',
        content: `From concept to deployment, we create tailored software solutions in Nigeria that drive business growth and 
        operational efficiency.
  `
      },
      technologies: [
        {
          category: 'Frontend',
          stack: ['React', 'Angular', 'Vue.js', 'TypeScript'],
          image: webDev
        },
        {
          category: 'Backend',
          stack: ['Node.js', 'Python', '.NET', 'Java'],
          image: softwareDevBg
        }
      ],
      methodologies: ['Agile Development', 'DevOps', 'CI/CD', 'Cloud-Native']
    }
  },
  'office-setup': {
    title: 'Office IT Setup',
    bgImage: officeBg,//change
    description: 'Complete workplace IT solutions for seamless operations',
    sections: {
      overview: {
        title: 'Turnkey IT Infrastructure',
        content: `We offer complete office IT setup solutions, including networking, workstations, and server installations in Nigeria. Our solutions ensure a seamless and productive work environment.`,
      },
      services: [
        {
          title: 'Network Configuration',
          description: 'Structured cabling, routers, and Wi-Fi setup for optimal connectivity',
        },
        {
          title: 'Workstation Setup',
          description: 'Provisioning and installation of desktops, laptops, and peripherals',
        },
        {
          title: 'Server Installation & Cloud Integration',
          description: 'On-premise and cloud-based server deployment for scalability',
        },
      ],
      features: [
        'High-Speed Network',
        'Data Backup Solutions',
        'IT Security Policies',
        'Scalable Infrastructure',
      ],
    },
  },

  'access-control': {
    title: 'Access Control & Intercom',
    bgImage: accessBg,//change
    description: 'Secure entry management with advanced authentication systems',
    sections: {
      overview: {
        title: 'Smart Access Solutions',
        content: `We provide advanced access control and intercom systems that enhance security and streamline visitor management in Nigeria.`,
      },
      systems: [
        {
          title: 'Biometric Authentication',
          description: 'Fingerprint, facial recognition, and iris scanning for secure access',
        },
        {
          title: 'Mobile & RFID Integration',
          description: 'Access control through mobile apps and RFID keycards',
        },
        {
          title: 'Intercom & Video Door Phones',
          description: 'Two-way audio/video communication for enhanced security',
        },
      ],
      benefits: [
        'Secure Premises',
        'Real-time Access Logs',
        'Remote Management',
        'Integration with CCTV',
      ],
    },
  },

  'it-support': {
    title: 'IT Support & AMC',
    bgImage: supportBg,//change
    description: '24/7 technical support and annual maintenance contracts',
    sections: {
      overview: {
        title: 'Comprehensive IT Support',
        content: `We offer proactive maintenance and on-demand support to ensure your IT systems run smoothly.`,
      },
      plans: [
        {
          title: 'Basic Support',
          features: ['Remote Assistance', 'Monthly System Checkups'],
        },
        {
          title: 'Premium Support',
          features: ['24/7 Monitoring', 'Onsite Support', 'Emergency Response'],
        },
        {
          title: 'Enterprise AMC',
          features: ['Dedicated IT Engineer', 'Unlimited Support Tickets', 'Hardware Replacement Assistance'],
        },
      ],
      advantages: [
        'Minimized Downtime',
        'Proactive Monitoring',
        'Cost-Effective Maintenance',
        'Expert IT Consultation',
      ],
    },
  },

  'structured-cabling': {
    title: 'Structured Cabling',
    bgImage: structureBg,//change
    description: 'Professional network cabling solutions for businesses',
    sections: {
      overview: {
        title: 'Reliable & Scalable Infrastructure in Nigeria',
        content: `Our structured cabling solutions provide a robust foundation for high-speed networks and seamless communication.`,
      },
      cablingTypes: [
        {
          title: 'CAT6 & CAT7',
          description: 'High-speed data transfer for modern businesses',
        },
        {
          title: 'Fiber Optic Cabling',
          description: 'High-bandwidth connectivity for long-distance networking',
        },
        {
          title: 'Data Center Cabling',
          description: 'TIA-942 compliant structured cabling for enterprise data centers',
        },
      ],
      benefits: [
        'Future-Proof Network',
        'Minimal Downtime',
        'Improved Data Transmission',
        'Cost-Effective Installation',
      ],
    },
  },
  'ip-babx': {
    title: 'Telephone/IP Babx Installation',
    bgImage: cybersecurityBg, // Ensure you have this image imported
    description: 'Reliable telephone and IP babx installation for modern communication systems',
    sections: {
      overview: {
        title: 'Professional Communication Solutions',
        content: `Our Telephone/IP Babx Installation service offers robust and scalable communication systems tailored for both businesses and residential setups in Nigeria. We ensure seamless integration with existing infrastructures while providing reliable voice and data solutions.`
      },
      features: [
        'High-Quality Voice Transmission',
        'Seamless Integration with IT Systems',
        'Scalable Solutions for Business Growth',
        'Advanced Security Features'
      ],
      services: [
        {
          title: 'System Installation',
          description: 'Expert installation of telephone/IP babx systems with minimal disruption.'
        },
        {
          title: 'Configuration & Testing',
          description: 'Comprehensive configuration and rigorous testing for optimal performance.'
        },
        {
          title: 'Maintenance & Support',
          description: 'Ongoing maintenance and technical support for sustained communication efficiency.'
        }
      ]
    }
  },

  'other-solutions': {
    title: 'Other IT Solutions',
    bgImage: cybersecurityBg,//change
    description: 'Custom technology solutions tailored to your business needs',
    sections: {
      overview: {
        title: 'Custom Implementations',
        content: `We offer specialized IT solutions, from cloud migration to disaster recovery, designed to enhance your digital transformation in Nigeria.`,
      },
      solutions: [
        {
          title: 'Cloud Migration',
          description: 'Seamless transition to cloud platforms like AWS, Azure, and Google Cloud',
        },
        {
          title: 'Disaster Recovery',
          description: 'Business continuity planning and data recovery solutions',
        },
        {
          title: 'Virtualization & AI Integration',
          description: 'Optimize infrastructure with virtual servers and AI-powered automation',
        },
      ],
      partners: ['Microsoft Azure', 'Google Cloud', 'AWS', 'VMware', 'IBM Cloud'],
    },
  },
};

const ServiceDetail = () => {
  const { serviceId } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const service = servicesData[serviceId];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [serviceId]);

  if (!service) return <div className="not-found">Service not found</div>;

 


  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="service-detail"
    >
      <div 
        className="service-hero"
        style={{ backgroundImage: `url(${service.bgImage})` }}
      >
        <div className="hero-overlay">
          <h1>{service.title}</h1>
          <p>{service.description}</p>
        </div>
      </div>

      <div className="service-content-container">
        <Link to="/" className="back-button">
          &larr; Back to Services
        </Link>

        {serviceId === 'home-security' && (<section className="overview-section">
          <motion.div
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.6 }}
          >
            <h2>{service.sections.overview.title}</h2>
            <p className="overview-content">{service.sections.overview.content}</p>
          </motion.div>
        </section>)}
        {serviceId === 'cybersecurity' && (<section className="overview-section">
          <motion.div
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.6 }}
          >
            <h2>{service.sections.overview.title}</h2>
            <p className="overview-content">{service.sections.overview.content}</p>
          </motion.div>
        </section>)}
        {serviceId === 'software-development' && (<section className="overview-section">
          <motion.div
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.6 }}
          >
            <h2>{service.sections.overview.title}</h2>
            <p className="overview-content">{service.sections.overview.content}</p>
          </motion.div>
        </section>)}

        {/* Home Security Content */}
        {serviceId === 'home-security' && (
          <motion.div
            initial={{ y: 20 }}
            animate={{ y: 0 }}
            className="security-content"
          >

            {/* Add this new section */}
    <section className="coverage-section testSec">
      {/* <h2 style={{color:"white"}}>Comprehensive Security Coverage</h2> */}
      <div className="camera-grid">
        <motion.div 
          whileHover={{ scale: 1.02 }}
          className="feature-item"
        >
          <div className="coverage-icon">🏡</div>
          <h3>Residential Security</h3>
          <p>Smart home integration & family protection</p>
          <ul>
            <li>Perimeter Security</li>
            <li>Smart Lock Integration</li>
            <li>Baby/Pet Monitoring</li>
            <li>24/7 Emergency Response</li>
          </ul>
        </motion.div>

        <motion.div 
          whileHover={{ scale: 1.02 }}
          className="feature-item"
        >
          <div className="coverage-icon">🏢</div>
          <h3>Commercial Security</h3>
          <p>Office & retail space protection solutions</p>
          <ul>
            <li>Access Control Systems</li>
            <li>Employee Safety Monitoring</li>
            <li>Inventory Protection</li>
            <li>After-Hours Surveillance</li>
          </ul>
        </motion.div>

        <motion.div 
          whileHover={{ scale: 1.02 }}
          className="feature-item"
        >
          <div className="coverage-icon">🏭</div>
          <h3>Industrial Security</h3>
          <p>Heavy-duty site protection systems</p>
          <ul>
            <li>Hazard Zone Monitoring</li>
            <li>Vehicle Tracking</li>
            <li>Perimeter Intrusion Detection</li>
            <li>Explosion-Proof Cameras</li>
          </ul>
        </motion.div>
      </div>
    </section>
            
            <section className="camera-types testSec">
              <h2 style={{color:"white"}}>Our CCTV Solutions</h2><br/>
              <div className="camera-grid">
                {service.sections.cameraTypes.map((camera, index) => (
                  <motion.div 
                    key={index}
                    whileHover={{ scale: 1.02 }}
                    className="camera-card"
                  >
                    <img src={camera.image} alt={camera.title} />
                    <div className="camera-info">
                      <h3>{camera.title}</h3>
                      <p>{camera.description}</p>
                      <div className="specs-grid">
                        {camera.specs.map((spec, i) => (
                          <div key={i} className="spec-item">
                            <span>{spec}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </motion.div>
                ))}
              </div>
            </section>

            <section className="security-features testSec">
              <h2 style={{color:"white"}}>Smart Security Features</h2>
              <div className="features-grid">
                {service.sections.features.map((feature, index) => (
                  <motion.div 
                    key={index}
                    whileHover={{ scale: 1.05 }}
                    className="feature-item"
                  >
                    <div className="feature-icon" style={{backgroundColor:"rgba(41, 48, 94, 0.95)"}}>{index + 1}</div>
                    <h3>{feature}</h3>
                  </motion.div>
                ))}
              </div>
            </section>
          </motion.div>
        )}

        {/* Cybersecurity Content */}
        {serviceId === 'cybersecurity' && (
          <motion.div
            initial={{ y: 20 }}
            animate={{ y: 0 }}
            className="cyber-content"
          >
            <section className="security-services testSec">
              <h2 style={{color:"white"}}>Our Cybersecurity Solutions</h2><br/>
              <div className="services-grid">
                {service.sections.services.map((service, index) => (
                  <motion.div 
                    key={index}
                    whileHover={{ scale: 1.02 }}
                    className="service-card"
                  >
                    <img src={service.image} alt={service.title} />
                    <div className="service-info">
                      <h3>{service.title}</h3>
                      <p>{service.description}</p>
                      <ul>
                        {service.features.map((feature, i) => (
                          <li key={i}>{feature}</li>
                        ))}
                      </ul>
                    </div>
                  </motion.div>
                ))}
              </div>
            </section>

            <section className="partners-section testSec">
              <h2 style={{color:"white"}}>Trusted Technology Partners</h2>
              <div className="partners-grid">
                {service.sections.partners.map((partner, index) => (
                  <motion.div 
                    key={index}
                    whileHover={{ scale: 1.1 }}
                    className="partner-logo"
                  >
                    {partner}
                  </motion.div>
                ))}
              </div>
            </section>
          </motion.div>
        )}

        {/* Software Development Content */}
        {serviceId === 'software-development' && (
          <motion.div
            initial={{ y: 20 }}
            animate={{ y: 0 }}
            className="software-content"
          >
            <section className="tech-stack testSec">
              <h2 style={{color:"white"}}>Our Technology Stack</h2><br/>
              <div className="stack-grid">
                {service.sections.technologies.map((tech, index) => (
                  <motion.div 
                    key={index}
                    whileHover={{ scale: 1.02 }}
                    className="tech-card"
                  >
                    <img src={tech.image} alt={tech.category} />
                    <div className="tech-info">
                      <h3>{tech.category} Development</h3>
                      <div className="tech-list">
                        {tech.stack.map((item, i) => (
                          <span key={i} className="tech-item">{item}</span>
                        ))}
                      </div>
                    </div>
                  </motion.div>
                ))}
              </div>
            </section>

            <section className="methodologies testSec">
              <h2 style={{color:"white"}}>Development Methodologies</h2>
              <div className="method-grid">
                {service.sections.methodologies.map((method, index) => (
                  <motion.div 
                    key={index}
                    whileHover={{ scale: 1.05 }}
                    className="method-card"
                  >
                    <div className="method-icon">{index + 1}</div>
                    <h3>{method}</h3>
                  </motion.div>
                ))}
              </div>
            </section>
          </motion.div>
        )}



        {/* Office Setup Content */}
{serviceId === 'office-setup' && (
  <motion.div
    initial={{ y: 20 }}
    animate={{ y: 0 }}
    className="office-content"
  >
    <section className="setup-services testSec">
      <h2 style={{color:"white"}}>Office IT Solutions</h2><br/>
      <div className="services-grid">
        {service.sections.services.map((item, index) => (
          <motion.div 
            key={index}
            whileHover={{ scale: 1.02 }}
            className="service-card"
          >
            <div className="service-info">
              <h3>{item.title}</h3>
              <p>{item.description}</p>
            </div>
          </motion.div>
        ))}
      </div>
    </section>

    <section className="setup-features testSec">
      <h2 style={{color:"white"}}>Key Features</h2>
      <div className="features-grid">
        {service.sections.features.map((feature, index) => (
          <motion.div 
            key={index}
            whileHover={{ scale: 1.05 }}
            className="feature-item"
          >
            <div className="feature-icon" style={{backgroundColor:"rgba(41, 48, 94, 0.95)"}}>{index + 1}</div>
            <h3>{feature}</h3>
          </motion.div>
        ))}
      </div>
    </section>
  </motion.div>
)}

{/* Access Control Content */}
{serviceId === 'access-control' && (
  <motion.div
    initial={{ y: 20 }}
    animate={{ y: 0 }}
    className="access-content"
  >
    <section className="systems-section testSec">
      <h2 style={{color:"white"}}>Security Systems</h2><br/>
      <div className="systems-grid">
        {service.sections.systems.map((system, index) => (
          <motion.div 
            key={index}
            whileHover={{ scale: 1.02 }}
            className="system-card"
          >
            <div className="system-info">
              <h3>{system.title}</h3>
              <p>{system.description}</p>
            </div>
          </motion.div>
        ))}
      </div>
    </section>

    <section className="benefits-section testSec">
      <h2 style={{color:"white"}}>System Benefits</h2>
      <div className="benefits-grid" >
        {service.sections.benefits.map((benefit, index) => (
          <motion.div 
            key={index}
            whileHover={{ scale: 1.05 }}
            className="benefit-item"
            style={{backgroundColor:'white',padding:'6px',borderRadius:'4px'}}
          >
            <div className="benefit-icon" style={{backgroundColor:"rgba(41, 48, 94, 0.95)"}}>
              {index + 1}
            </div>
            <h3>{benefit}</h3>
          </motion.div>
        ))}
      </div>
    </section>
  </motion.div>
)}

{/* IT Support Content */}
{serviceId === 'it-support' && (
  <motion.div
    initial={{ y: 20 }}
    animate={{ y: 0 }}
    className="support-content"
  >
    <section className="support-plans testSec">
      <h2 style={{color:"white"}}>Support Plans</h2><br/>
      <div className="plans-grid">
        {service.sections.plans.map((plan, index) => (
          <motion.div 
            key={index}
            whileHover={{ scale: 1.02 }}
            className="plan-card"
          >
            <h3>{plan.title}</h3>
            <ul>
              {plan.features.map((feature, i) => (
                <li key={i}>{feature}</li>
              ))}
            </ul>
          </motion.div>
        ))}
      </div>
    </section>

    <section className="advantages-section testSec">
      <h2 style={{color:"white"}}>Key Advantages</h2>
      <div className="advantages-grid">
        {service.sections.advantages.map((advantage, index) => (
          <motion.div 
            key={index}
            whileHover={{ scale: 1.05 }}
            className="advantage-item"
            style={{backgroundColor:'white',padding:'4px',borderRadius:'4px',fontSize:'14px'}}
          >
            <div className="advantage-icon" style={{backgroundColor:"rgba(41, 48, 94, 0.95)"}}>
              {index + 1}
            </div>
            <h3>{advantage}</h3>
          </motion.div>
        ))}
      </div>
    </section>
  </motion.div>
)}

{/* Structured Cabling Content */}
{serviceId === 'structured-cabling' && (
  <motion.div
    initial={{ y: 20 }}
    animate={{ y: 0 }}
    className="cabling-content"
  >
    <section className="cabling-types testSec">
      <h2 style={{color:"white"}}>Cabling Solutions</h2><br/>
      <div className="types-grid">
        {service.sections.cablingTypes.map((type, index) => (
          <motion.div 
            key={index}
            whileHover={{ scale: 1.02 }}
            className="type-card"
          >
            <h3>{type.title}</h3>
            <p>{type.description}</p>
          </motion.div>
        ))}
      </div>
    </section>

    <section className="cabling-benefits testSec">
      <h2 style={{color:"white"}}>Infrastructure Benefits</h2><br/>
      <div className="benefits-grid">
        {service.sections.benefits.map((benefit, index) => (
          <motion.div 
            key={index}
            whileHover={{ scale: 1.05 }}
            className="benefit-item"
            style={{backgroundColor:'white',padding:'4px',borderRadius:'4px',fontSize:'14px'}}
          >
            <div className="benefit-icon" style={{backgroundColor:"rgba(41, 48, 94, 0.95)"}}>
              {index + 1}
            </div>
            <h3>{benefit}</h3>
          </motion.div>
        ))}
      </div>
    </section>
  </motion.div>
)}



{/* Telephone/IP Babx Installation */}
{serviceId === 'ip-babx' && (
  <motion.div
    initial={{ y: 20 }}
    animate={{ y: 0 }}
    className="office-content"
  >
    <section className="setup-services testSec">
      <h2 style={{color:"white"}}>Telephone/IP Babx Installation</h2><br/>
      <div className="services-grid">
        {service.sections.services.map((item, index) => (
          <motion.div 
            key={index}
            whileHover={{ scale: 1.02 }}
            className="service-card"
          >
            <div className="service-info">
              <h3>{item.title}</h3>
              <p>{item.description}</p>
            </div>
          </motion.div>
        ))}
      </div>
    </section>

    <section className="setup-features testSec">
      <h2 style={{color:"white"}}>Key Features</h2>
      <div className="features-grid">
        {service.sections.features.map((feature, index) => (
          <motion.div 
            key={index}
            whileHover={{ scale: 1.05 }}
            className="feature-item"
          >
            <div className="feature-icon" style={{backgroundColor:"rgba(41, 48, 94, 0.95)"}}>{index + 1}</div>
            <h3>{feature}</h3>
          </motion.div>
        ))}
      </div>
    </section>
  </motion.div>
)}

{/* Other Solutions Content */}
{serviceId === 'other-solutions' && (
  <motion.div
    initial={{ y: 20 }}
    animate={{ y: 0 }}
    className="solutions-content"
  >
    <section className="solutions-list testSec">
      <h2 style={{color:"white"}}>Specialized Solutions</h2><br/>
      <div className="solutions-grid">
        {service.sections.solutions.map((solution, index) => (
          <motion.div 
            key={index}
            whileHover={{ scale: 1.02 }}
            className="solution-card"
          >
            <h3>{solution.title}</h3>
            <p>{solution.description}</p>
          </motion.div>
        ))}
      </div>
    </section>

    <section className="partners-section testSec">
      <h2 style={{color:"white"}}>Technology Partners</h2>
      <div className="partners-grid">
        {service.sections.partners.map((partner, index) => (
          <motion.div 
            key={index}
            whileHover={{ scale: 1.1 }}
            className="partner-logo"
          >
            {partner}
          </motion.div>
        ))}
      </div>
    </section>
  </motion.div>
)}
         

        {/* <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className="quote-button"
          onClick={() => setIsModalOpen(true)}
        >
          Get Free Quote
        </motion.button> */}

<motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className="cta-button"
          onClick={() => setIsModalOpen(true)}
        >
          Get Free Quote
        </motion.button>
      

      </div>

      <AnimatePresence>
        {isModalOpen && (
          <QuoteModal onClose={() => setIsModalOpen(false)} service={service.title} />
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default ServiceDetail;