import { useState } from 'react';
import { motion } from 'framer-motion';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import './App.css';
import future from "../src/assets/future.jpg"
import techgroup from "../src/assets/techgroup.jpg"
import accessControlThumbnail from "./assets/accessControlThumbnail.jpeg"
import cablingThumbnail from "./assets/cablingThumbnail.jpeg"
import cctvThumbnail from "./assets/cctvThumbnail.jpeg"
import dist from "./assets/dist.jpeg"
import officeSetupThumbnail from "./assets/officeSetupThumbnail.jpeg"
import otherSolutionsThumbnail from "./assets/otherSolutionsThumbnail.jpeg"
import softwareThumbnail from "./assets/softwareThumbnail.jpeg"
import supportThumbnail from "./assets/supportThumbnail.jpeg"
import ipBabxThumbnail from "./assets/ipBabxThumbnail.jpeg"
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function Home() {
  const [menuOpen, setMenuOpen] = useState(false);

  const scrollToSection = (id) => {
    document.getElementById(id)?.scrollIntoView({ behavior: 'smooth' });
    setMenuOpen(false);
  };

  return (
    <div className="App" id='home'>
      <Nav menuOpen={menuOpen} setMenuOpen={setMenuOpen} scrollToSection={scrollToSection} />
      <Hero />
      <Features />
      <About />
      <Contact />
      <Footer />
    </div>
  );
}

const Nav = ({ menuOpen, setMenuOpen, scrollToSection }) => {
  const navItems = [
    { id: 'home', label: 'Home' },
    { id: 'solutions', label: 'Solutions' },
    { id: 'about', label: 'About' },
    { id: 'contact', label: 'Contact' },
  ];

  return (
    <motion.nav 
      initial={{ y: -100 }}
      animate={{ y: 0 }}
      transition={{ duration: 0.8 }}
      className="navbar"
    >
      <motion.div whileHover={{ scale: 1.05 }} className="logo">
        <img src={future} alt='FTG' style={{position:'absolute',top:'10px'}}/>
        {/* FTG */}
      </motion.div>

      <div className={`nav-items ${menuOpen ? 'open' : ''}`}>
        {navItems.map((item) => (
          <motion.a
            key={item.id}
            whileHover={{ scale: 1.1, color: '#2C7963' }}
            className="nav-item"
            onClick={() => scrollToSection(item.id)}
          >
            {item.label}
          </motion.a>
        ))}
      </div>

      <div className={`hamburger ${menuOpen ? 'open' : ''}`} onClick={() => setMenuOpen(!menuOpen)}>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </div>
    </motion.nav>
  );
};

const Hero = () => {
  return (
    <section className="hero" id="hero">
      <div className="hero-content">
        <motion.h1
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
          className="hero-title"
        >
          Shaping the Future of
          <span className="gradient-text"> Technology</span>
        </motion.h1>
        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5, duration: 1 }}
          className="hero-subtitle"
        >
          Innovating tomorrow's solutions today
        </motion.p><br/>
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className="cta-button"
          onClick={() => window.location.href = '#solutions'}
        >
          Explore Innovations
        </motion.button>
      </div>
      <motion.div 
        animate={{
          y: [0, -20, 0],
        }}
        transition={{
          duration: 4,
          repeat: Infinity,
          ease: "easeInOut",
        }}
        className="hero-image"
      >
        <img src={techgroup} alt="Future Technology" />
      </motion.div>
    </section>
  );
};
const FeatureCard = ({ title, description, index, id, thumbnail }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <Link to={`/services/${id}`} className="feature-link">
      <motion.div
        ref={ref}
        initial={{ opacity: 0, y: 50 }}
        animate={inView ? { opacity: 1, y: 0 } : {}}
        transition={{ delay: index * 0.2, duration: 0.6 }}
        className="feature-card"
      >
        <div className="thumbnail-container">
          <img src={thumbnail} alt={title} className="service-thumbnail" />
          <div className="thumbnail-overlay"></div>
        </div>
        <div className="feature-content">
          <h3>{title}</h3>
          <p>{description}</p>
        </div>
      </motion.div>
    </Link>
  );
};
const Features = () => {
  const features = [
    { 
      id: 'home-security',
      title: 'Home Security & CCTV Installations',
      description: 'Advanced security & intelligence systems',
      thumbnail: cctvThumbnail,
    },
    { 
      id: 'access-control',
      title: 'Access Control & Intercom',
      description: 'Secure entry systems',
      thumbnail: accessControlThumbnail,
    },
    { 
      id: 'ip-babx',
      title: 'Telephone/IP Babx Installation',
      description: 'Seamless installation and integration',
      thumbnail: ipBabxThumbnail, // Make sure to import this thumbnail appropriately
    },
    { 
      id: 'cybersecurity',
      title: 'Cyber Security & IT Distribution',
      description: 'Enterprise-grade hardware solutions',
      thumbnail: dist,
    },
    { 
      id: 'office-setup',
      title: 'Office IT Setup',
      description: 'Complete workplace solutions',
      thumbnail: officeSetupThumbnail,
    },
    { 
      id: 'it-support',
      title: 'IT Support & AMC',
      description: '24/7 technical support',
      thumbnail: supportThumbnail,
    },
    { 
      id: 'software-development',
      title: 'Website and App Development',
      description: 'Custom digital solutions',
      thumbnail: softwareThumbnail,
    },
    { 
      id: 'structured-cabling',
      title: 'Structured Cabling',
      description: 'Network infrastructure solutions',
      thumbnail: cablingThumbnail,
    },
    { 
      id: 'other-solutions',
      title: 'Other IT Solutions',
      description: 'Tailored tech solutions',
      thumbnail: otherSolutionsThumbnail,
    }
  ];

  return (
    <section className="features" id='solutions'>
      <h2>Our Solutions</h2>
      <div className="features-grid">
        {features.map((feature, index) => (
          <FeatureCard key={feature.id} {...feature} index={index} />
        ))}
      </div>
    </section>
  );
};

// Add these new components
const About = () => {
  const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.1 });

  return (
    <section className="section about-section" id="about">
      <motion.div
        ref={ref}
        initial={{ opacity: 0 }}
        animate={inView ? { opacity: 1 } : {}}
        transition={{ duration: 0.8 }}
        className="about-content"
      >
        <h2>Pioneering Tomorrow's Solutions</h2>
        <div className="about-grid">
          <motion.div 
            initial={{ x: -100, opacity: 0 }}
            animate={inView ? { x: 0, opacity: 1 } : {}}
            transition={{ delay: 0.2, duration: 0.8 }}
            className="about-card mission"
          >
            <h3>Our Mission</h3>
            <p>
              To accelerate human progress through ethical technological innovation, 
              creating solutions that bridge today's challenges with tomorrow's possibilities.
            </p>
            <div className="glow-effect"></div>
          </motion.div>

          <motion.div 
            initial={{ x: 100, opacity: 0 }}
            animate={inView ? { x: 0, opacity: 1 } : {}}
            transition={{ delay: 0.4, duration: 0.8 }}
            className="about-card vision"
          >
            <h3>Our Vision</h3>
            <p>
              A world where technology seamlessly enhances every aspect of human life, 
              fostering global connectivity while preserving our planet's future.
            </p>
            <div className="glow-effect"></div>
          </motion.div>

          <motion.div 
            initial={{ y: 100, opacity: 0 }}
            animate={inView ? { y: 0, opacity: 1 } : {}}
            transition={{ delay: 0.6, duration: 0.8 }}
            className="about-card values"
          >
            <h3>Core Values</h3>
            <ul>
              <li>Ethical Innovation</li>
              <li>Sustainable Progress</li>
              <li>Human-Centric Design</li>
              <li>Collaborative Excellence</li>
            </ul>
            <div className="glow-effect"></div>
          </motion.div>
        </div>
      </motion.div>
    </section>
  );
};

const Contact = () => {
  const [formData, setFormData] = useState({ name: '', email: '',phone:'', message: '' });

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Add your form submission logic here
   // console.log(formData);
   const data = new FormData();

    for (const key in formData) {
      data.append(key, formData[key]);
    }

    try {
      const response = await axios.post('https://futuretechgroup.net/contactEmail.php', data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      //alert(response.data);
      toast.success(response.data, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.error('There was an error sending the email!', error);

      toast.error('Failed to send message! Please try again.', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <section className="section contact-section" id="contact">
      <div className="contact-container">
        <motion.div 
          initial={{ x: -100, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          className="contact-address"
        >
          <h2>Our Headquarters</h2>
          <motion.div 
            whileHover={{ scale: 1.05 }}
            className="address-card"
          >
            <h3>Future Technology Group</h3>
            <p>Marshyhill Estate</p>
            <p>No 2, Bisi Afolabi street</p>
            <p>Addo-Road, Lagos 10245</p>
            <div className="map-glow"></div>
          </motion.div>
        </motion.div>

        <motion.form 
          initial={{ x: 100, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          onSubmit={handleSubmit}
          className="contact-form"
        >
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.2 }}
          >
            <input
              type="text"
              placeholder="Your Name"
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
              required
            />
          </motion.div>
          
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.4 }}
          >
            <input
              type="email"
              placeholder="Your Email"
              value={formData.email}
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
              required
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.6 }}
          >
            <input
               type="tel"
               placeholder="Your Phone number"
               value={formData.phone}
               onChange={(e) => setFormData({ ...formData, phone: e.target.value.replace(/\D/g, '') })} // Remove non-numeric input
               pattern="[0-9]*"
               required
            />
          </motion.div>

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.8 }}
          >
            <textarea
              placeholder="Your Message"
              value={formData.message}
              onChange={(e) => setFormData({ ...formData, message: e.target.value })}
              required
            />
          </motion.div>

          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            type="submit"
            className="cta-button"
          >
            Get in Touch
          </motion.button>
        </motion.form>
      </div>
    </section>
  );
};

// ... (keep existing FeatureCard and Features components)

const Footer = () => {
  return (
    <motion.footer
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      className="footer"
    >
      <div className="wave"></div>
      <div className="footer-content">
        <div className="footer-section">
          <h4>Future Technology Group</h4>
          <p>Leading the way in technological innovation</p>
        </div>
        <div className="footer-links">
          {['Careers', 'Press', 'Blog'].map((item) => (
            <motion.a 
              whileHover={{ x: 10 }} 
              key={item}
              href={`#${item.toLowerCase()}`}
              className='foot'
            >
              {item}
            </motion.a>
          ))}
        </div>
      </div>
      <div className="footer-bottom">
        <p>© {new Date().getFullYear()} Future Technology Group. All rights reserved.</p>
      </div>
    </motion.footer>
  );
};

export default Home;